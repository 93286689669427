import "../styles/globals.css";
import type { AppProps } from "next/app";
import Link from "next/link";
import Image from "next/image";
import logo from "../public/logo.png";
import fbLogo from "../public/fb-logo.png";
import { slide as Menu } from "react-burger-menu";

import style from "../styles/layout.module.css";
import React from "react";

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Header />
      <main>
        <Component {...pageProps} />
      </main>
      <Footer />
    </>
  );
}

const MENU_STYLES = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "rgba(51, 51, 51, 1)",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "32px",
    width: "32px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "rgba(51, 51, 51, 1)",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "rgba(51, 51, 51, 1)",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "flex",
    flexDirection: "column",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  return (
    <>
      <Menu
        styles={MENU_STYLES}
        right
        isOpen={isMenuOpen}
        onStateChange={(state: { isOpen: boolean }) =>
          setIsMenuOpen(state.isOpen)
        }
      >
        <nav className={style.desktopNav}>
          <Link href="/">
            <a onClick={() => setIsMenuOpen(false)}>Home</a>
          </Link>
          <Link href="/register">
            <a onClick={() => setIsMenuOpen(false)}>Register</a>
          </Link>
          <Link href="/results">
            <a onClick={() => setIsMenuOpen(false)}>Past Results</a>
          </Link>
        </nav>
      </Menu>
      <header className={style.header}>
        <Link href="/">
          <a>
            <Image
              src={logo}
              alt="MFT2 Logo"
              className={style.logo}
              height={75}
              width={72}
            />
          </a>
        </Link>
      </header>
    </>
  );
}

function Footer() {
  return (
    <footer className={style.footer}>
      <div>
        <Image
          src={logo}
          alt="MFT2 Logo"
          className={style.logo}
          height={75}
          width={72}
        />
      </div>
      <div>
        <address>
          <strong>Event Location:</strong>
          <div>
            Department of Resources and
            <br />
            Economic Development,
            <br />
            Division of Parks and Recreation
          </div>
          <div>Mt. Monadnock</div>
          <div>Jaffrey, NH</div>
        </address>
      </div>
      <a
        href="https://www.facebook.com/MonadnockFullThrottle/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={fbLogo} alt="Facebook Logo" />
      </a>
      <div>
        <p>
          Like and follow us on facebook to get updates about the race. If you
          have an questions send us a message on our facebook page.
        </p>
      </div>
    </footer>
  );
}
